@import "../../assets/util.styles.scss";

.mobile-nav {
    display: none;
}

@media screen and (max-width: $pc-width) {
    .mobile-nav {
        display: block;
        min-width: 100%;
        min-height: 100%;
        position: relative;
        background-color: #b3d3ff;

        &-content {
            position: absolute;
            top: 15%;
            left: 10%;
            height: 50vh;
            // @include border();
        }
        &-links-group {
            font-family: "Fjalla One";
            font-size: 3rem;
            font-weight: bold;
            line-height: 6rem;
            display: flex;
            flex-direction: column;
        }
        a{
            color: #000;
        }
    }
}
