@import "../../App.scss";
@import "../../assets/util.styles.scss";

.portfolio {
    background-color: $second-color;
    color: $first-color;
    font-size: 1.3rem;
    min-height: 100%;
    &-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 4rem 0;
        @include responsive($pc-width) {
            width: 85%;
            margin: 0;
        }
    }
    &-title {
        @include page-title();
        overflow-wrap: break-word;
        @include responsive($pc-width) {
            font-size: 3.75rem;
            transform: translateX(1rem);
            width: 70%;
        }
    }

    &-block {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // @include responsive($pad-width) {
        //     flex-direction: column;
        // }
    }
    .gener{
        &-title{
            font-size: 2rem;
            text-indent: 2rem;
            margin-top: 2rem;
            display: inline-block;
            color:rgba($color: $first-color, $alpha: 0.8);
            text-shadow:
                        3px 3px 0 rgba(0,76,163,0.3);                     
            font-weight: 600;
            display: flex;
        }
        &-block{
            // border: 1px solid black;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include responsive($pad-width) {
                flex-direction: column;
                width: 100%;
            }
        }
    }
    
}
